body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrongField, .wrongField input, .wrongField select {
    border-color: #ff0d01 !important;
}

.curatedSetTracksDialog > div {
    max-width: 90vw !important;
}

.baseDialogContent{
    max-height: 98vh;
}

.baseDialogBody{
    overflow: auto;
}
div.InputFromTo.error > .DayPickerInput >  input,
span.InputFromTo-to.error .DayPickerInput  input {
  border-color: red;
}

.datePicerSect{
  margin-bottom: 20px;
}

.datePicerSect .DayPickerInput input{
  border-radius: 22px;
  border: 1px solid #ccc;
  padding: 7px 15px;
  width: 200px;
}

.datePicerSect label{
  font-weight: bold;
  font-size: 16px;
}

ul.list-inline.actionsUl li > a:hover svg {
  fill: #259d85;
  cursor: pointer;
}

button.close-button {
  background: aliceblue;
  border: 1px solid #42ffd9;
  margin: 5px;
  border-radius: 36px;
  padding: 2px 7px 4px 10px;
  position: relative;
}

span.close-icon {
  position: absolute;
  top: -5px;
  right: -4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: red;
  background: #ffffff;
  width: 12px;
  height: 13px;
  padding: 0;
  display: block;
  line-height: 0.55;
  border-radius: 15px;
  z-index: 999;
  border: 1px solid #fbb1b1;
}

.banner-form {
  background: #ebfffb;
}

.m-portlet .track-list {
  background: #d4e5e2;
}

.m-portlet {
  display: flow-root;
}

.loading {
  height: 135vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  /* filter: blur(7px); */
  background: #ffffffab;
}

.loader {
  width: 158px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 9;
  text-align: center;
  top: 40vh;
}

.css-bh4wfl-wrapper-Loader {
  margin: auto;
}

span.loading-text {
  font-size: large;
  font-weight: 600;
  letter-spacing: 1px;
}

.bannerList{
  position: relative;
}
.bannerList td {
  height: calc(160px* 300 / 700);
  overflow: hidden;
  position: relative;
}
.bannerList td img {
  width: auto;
  height: auto;
  border-radius: 5px;
  max-height: 63px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.loader.message {
  width: auto;
}
.loader.message span {
  font-size: 20px;
  font-family: sans-serif;
  letter-spacing: 0.4px;
  font-weight: 600;
}
.loader.message span.green-text {
  color: green;
}
.loader.message span.red-text {
  color: red;
}

.popupBannerWrap.banner-order-change .flexAlignment input{
  width: auto;
}
.popupBannerWrap.banner-order-change .flexAlignment{
  max-width: 400px;
}
.popupBannerWrap.banner-order-change .flexAlignment .checkBox{
  margin: 0px 0px 0px 10px;
  cursor: pointer;
}

.popupBannerWrap.banner-order-change .flexAlignment{
  padding: 40px 40px;
}

.popupBannerWrap.banner-order-change .flexAlignment .control-label{
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}
.popupBannerWrap.banner-order-change .flexAlignment  .btnGap{
  grid-gap: 20px;
  gap: 20px;
  display: flex;
}

.popupBannerWrap.banner-order-change .flexAlignment  .fontNormal{
  font-size: 15px;
}

.banner-order-change .flexAlignment input {
  margin:20px 8px;
  padding: 13px 18px;
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 100px;
  font-size: 15px;
  margin-bottom: 28px;
}
span.loading-text.banner-reupload-msg {
  white-space: nowrap;
  font-size: 22px;
  color: green;
  font-weight: 600;
}
.popup-container.banner-order-change {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
}
.banner-order-change .flexAlignment {
  position: relative;
  margin-bottom: 24px;
  grid-gap: 16px;
  gap: 16px;
  text-align:center;
  background: #fff;
  padding: 40px 60px;
  border-radius: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.banner-order-change .flexAlignment .btn{
  border-radius: 100px;
  padding: 13px 28px;
  font-size: 15px;
}

.banner-order-change .closeIcon i {
  font-size: 19px;
  cursor: pointer;
  opacity: .6;
  transition:all .5s ease;
}
.banner-order-change .closeIcon i:hover{
  opacity: 1;
}
.banner-order-change .closeIcon {
  position: absolute;
  top: 16px;
  right: 20px;
}
.banner-re-upload .flexAlignment input{
  margin: 0px 0px 20px 0px ! important;
}
.label_Inline label {
  text-align: left;
  width: auto;
  flex: unset;
}

.label_Inline {
  justify-content: left;
  margin: 0 !important;
}

.label_Inline input {
  width: 100% !important;
  max-width: 100% !important;
  flex: unset !important;
}
.TextField_form-text-field__183Kv {
  display: flex;
  align-items: center;
  justify-content: center; }
  .TextField_form-text-field__183Kv label {
    text-align: right;
    margin-bottom: 0; }

.Select_form-select__7sTjd {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Select_form-select__7sTjd label {
    text-align: right;
    margin-bottom: 0; }

.AddCuratedSetDialog_custom-modal-style__1DZfG {
  max-width: 650px; }

.AddCuratedSetDialog_header__1QnoW {
  margin-left: 70px; }

.DJUsers_text-field-label__3z-fJ {
  width: 10px; }

.DJUsers_left-panel__3BU3P {
  width: 47%;
  margin-right: 3%; }

.DJUsers_right-panel__1gamV {
  width: 47%;
  margin-right: 3%; }

.BillingDialog_custom-modal-style__2hzQi {
  max-width: 800px; }

.DJUserDetails_left-panel__oKHAo {
  width: 47%;
  margin-right: 3%; }

.DJUserDetails_right-panel__2F8_F {
  width: 47%;
  margin-right: 3%; }

.runScriptButton{
    align-self: center;
}

.runScriptDiv{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.consoleContent{
    white-space: pre-wrap;
    margin: 10px;
    overflow-y: auto;
    max-height: 50vh;
}

.runScriptContainer{
    margin: 15px 30px 0 30px;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
}

.runScriptButton{
    align-self: center;
}

.runScriptDiv{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.consoleContent{
    white-space: pre-wrap;
    margin: 10px;
    overflow-y: auto;
    max-height: 50vh;
}

.runScriptContainer{
    margin: 15px 30px 0 30px;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
}

.InvalidUsers_text-field-label__2SQdh {
  width: 10px; }

.InvalidUsers_left-panel__22pvD {
  width: 47%;
  margin-right: 3%; }

.InvalidUsers_right-panel__1iq-E {
  width: 47%;
  margin-right: 3%; }

.InvalidUsers_loading-indicator__2Etgu {
  display: flex;
  justify-content: center; }


.btn-send-djs ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    justify-content: center;
}

ul.pagination li {
    width: auto;
    padding: 4px 0px;
    font-size: 16px;
    font-weight: bold;
    color: #000 !important;
    border-radius: 5px;
    display: flex;
}
ul.pagination li.active {
    color: #fff !important;
    /*background: #34bfa3;*/
    cursor: pointer;
}
ul.pagination li.active a {
    color: #fff !important;
}
ul.pagination li.previous{
    /*font-size: 0px;*/
}
ul.pagination li a{
    color:#000;
    cursor: pointer;
    text-decoration: none;
}

.paginate {
  grid-column-gap: 0px;
  column-gap: 0px;
}

.right-contentarea .btn-send-djs ul li a {
    border-radius: 0;
    padding: 7px 10px;
}
ul.pagination li.previous a, ul.pagination li.next a {
    border: solid #2aa19c;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    cursor: pointer;
}
ul.pagination li.previous a {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
ul.pagination li.next a {
    transform: rotate(-45deg); -webkit-transform: rotate(-45deg);
    
}
.right-contentarea .btn-send-djs ul li a:hover {
    color: #fff;
}
ul.pagination li.previous a:hover, ul.pagination li.next a:hover {
    background: none;
    text-decoration: none;
    text-transform: none;
}
.pagination li.next.disabled,
.pagination li.previous.disabled {
    display: none;
}

.date-sec-wrap{
    display: flex;
    align-items: center;
}
.reset-search-btn{
    margin-left: 10px;

}
.reset-search-btn .reset-btn{
    margin-left: 10px;
}

@media (max-width:767px){
    div.date-sec-wrap{
        display: block;
    }
    div.date-sec-wrap .date-input-field-wrap{
        margin-bottom: 10px;
    }   
    div.date-sec-wrap .reset-search-btn{
        margin-left: 0px;
    }
     .datePicerSect div.date-sec-wrap .DayPickerInput input{
        width: 150px;
    }

}

